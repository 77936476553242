import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData, removeStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { toast } from "react-toastify";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { conditionSort } from "../../../components/src/Helper";
export interface TranslationInterface {
  subscriptions:string,
buyerSubscribeDetails:string,
anually:string,
subscribeBtnTxt:string,
subscribedBtnTxt:string,
renewSub: string,
takeMeBack: string,
doYouWish: string,
whatYouGet: string,
expiredText: string,
childHeading:string,
weAcceptFollowingCards:string,
cancelPayment:string,
proceedToPayment:string,
days:string,
remaining:string,
accessToken:string,
expiresOn:string,
active: string,
expired: string,
successText: string,
failure: string,
success: string,
close: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string) => void;
    getParam: (param: string) => string;
    goBack: () => void;
    addListener: (event: string, param: object) => void;
  };
  id: string;
  classes: { [key: string]: string };
}

interface S {
  // Customizable Area Start
  feature: any;
  data: any;
  token: string;
  featurePrice: number;
  featureName: string;
  featureId: string;
  userSubscriptionData: any;
  userSubscribed: boolean;
  showPaymentForm: boolean;
  remaining_days: string;
  total_token: string;
  expiration_date: string;
  subscription_status: string;
  userSubscribedPlan: string;
  userSubscribed_feature_benefits: any;
  formData: any;
  formErrors: any;
  checkoutId: string;
  pageTxtData: TranslationInterface;
  modalTexts: {
    title: string, 
    success: boolean,
    text: string
  }
  language: string;
  isSubscriptionSuccess: boolean

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class BuyerSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  checkSubscriptionAPICallId: string = "";
  fetchSubAPICallId: string = "";
  postPaymentAPICallId: string = "";
  postCheckoutAPICallID: string = "";
  postSubscriptionCallID: string = "";
  checkCheckoutStatusCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      feature: [],
      data: [],
      token: "",
      featurePrice: 0,
      featureName: "",
      featureId: "",
      userSubscriptionData: [],
      userSubscribed: false,
      showPaymentForm: false,
      remaining_days: "",
      total_token: "",
      expiration_date: "",
      subscription_status: "",
      userSubscribedPlan: "",
      userSubscribed_feature_benefits: [],
      formData: {},
      formErrors: {},
      checkoutId: "",
      language: "",
      isSubscriptionSuccess: false,
      pageTxtData: {
        subscriptions: '',
        buyerSubscribeDetails: '',
        anually: '',
        subscribeBtnTxt: '',
        subscribedBtnTxt: '',
        takeMeBack: '',
        renewSub: '',
        doYouWish: '',
        expiredText: '',
        whatYouGet: '',
        childHeading: '',
        weAcceptFollowingCards: '',
        cancelPayment: '',
        proceedToPayment: '',
        days:'',
        remaining:'',
        accessToken:'',
        expiresOn:'',
        active: '',
        expired: '',
        successText: '',
        success: '',
        failure: '',
        close: ''
      },
      modalTexts: {
        title: '', 
        success: false,
        text: ''
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token1 = await getStorageData("token");
      this.receiveTokenValue(token1);
    }
    if (
      this.checkSubscriptionAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson?.subscription_plan?.data) {
        const {subscription_plan, remaining_days, total_token, expiration_date, subscription_status} = responseJson
        this.setState({
          userSubscribed: true,
          featureName: this.getSpecifiedLangString(this.state.language,subscription_plan.data.attributes.name,subscription_plan.data.attributes.arabic_name),
          remaining_days,
          total_token,
          expiration_date,
          subscription_status: ['active', 'نشيط'].includes(subscription_status) ? 'active' : 'expired',
          userSubscribedPlan: subscription_plan.data.attributes.name,
          userSubscribed_feature_benefits:
            subscription_plan.data.attributes.feature_benefits,
        });
      }
      if (responseJson?.error) {
        this.setState({ userSubscribed: false });
        this.fetchSubscriptionDetails(this.state.token);
      }
    }

    if (
      this.fetchSubAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      const responseJson: any = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson?.data?.length > 0) {
        this.setState({ featureName: responseJson.data[0].attributes.name, 
          featureId: responseJson.data[0].attributes.id,
          featurePrice: responseJson.data[0].attributes.price,
          feature: [...responseJson.data[0].attributes.feature_benefits]}, () => this.checkCheckoutStatus());
      }
    }

    this.receivePostApi(message);

    this.receiveCheckoutPostApi(message);

    this.receiveSubscriptionPostApi(message);
    this.receiveCheckoutGetApi(message);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    let lang = await getStorageData("language");
    this.applyTranslation(lang);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area Start
  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }
  buyerSubscribe = () => {

    const headers = {
      token: this.state.token,
    };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const formData = new URLSearchParams();
      formData.append("amount", this.state.featurePrice.toString() )
      this.postCheckoutAPICallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCheckoutApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `${configJSON.postMethod}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  

  };

  getIfConditionStatement = (condition: boolean, ifTrue: any, ifFalse: any) => {
    return condition ? ifTrue : ifFalse;
  };
 
  subscribeApiFn  = async () => {    
    const headers = this.handleHeaders();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSubscriptionCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSubscriptionApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({subscription_plan_id: this.state.featureId})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.postMethod}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkCheckoutStatus = async () => {
    const queryParams = new URLSearchParams(window.location.href);
    const queryParamsObject = Object.fromEntries(queryParams);

    const [firstValue] = Object.values(queryParamsObject);

    let localId = await getStorageData("checkoutId");
    if(firstValue !== localId) {
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkCheckoutStatusCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      conditionSort(!["https://usenamifinal2-360269-react.b360269.uat.eastus.az.svc.builder.ai", "https://www.usenami.com"].includes(window.location.origin), `https://test.oppwa.com/v1/checkouts/${localId}/payment`, `https://test.oppwa.com/v1/checkouts/${localId}/payment`)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getSubscriptionAPiMethod}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  receiveTokenValue = (token: string) => {
    this.setState({ token: token }, () => {
      if (token) {
        this.checkSubscriptionDetails(token);
      } else {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    });
  };

  receiveCheckoutPostApi = (message: any) => {
    if (
      this.postCheckoutAPICallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.response.result.parameterErrors && responseJson.response.result.parameterErrors.length) {
        toast.error(responseJson.response.result.description);
      }
      else {
      this.setState({ showPaymentForm: !this.state.showPaymentForm, formData: {}, formErrors: {}, checkoutId: responseJson.response.id });
        setStorageData("checkoutId", responseJson.response.id);
      }
      
    }
  }

  validateResultCode = (resultCode: string) => {
    let result = false;
    if (/^(000\.000\.|000\.100\.1|000\.[36]|000\.400\.[12]0)/.test(resultCode) || /^(000\.400\.0[^3]|000\.400\.100)/.test(resultCode)) {
      result = true;
    }
    return result;
  }

  receivePostApi = (message: any) => {
    if (
      this.postPaymentAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.response.result.parameterErrors && responseJson.response.result.parameterErrors.length) {
        toast.error(responseJson.response.result.description);
      }
      else {
        toast.success('Card saved successfully');
        this.setState({ showPaymentForm: false });
        
      }
      
    }
  }

  receiveCheckoutGetApi = (message: any) => {
    
    if (
      this.checkCheckoutStatusCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(this.validateResultCode(responseJson.result.code)) {
        this.subscribeApiFn();
      } else {
        this.setState({isSubscriptionSuccess: true, modalTexts: {
          success: false,
          title: this.state.pageTxtData.failure,
          text: responseJson.result.description
        }});
        removeStorageData("checkoutId");
      }

    }

  }

  receiveSubscriptionPostApi = (message: any) => {
    if (
      this.postSubscriptionCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      removeStorageData("checkoutId")
      if(responseJson.data && responseJson.data.id) {
          setStorageData("isUserSubscribed", true);
            this.getToken();
          this.setState({isSubscriptionSuccess: true, modalTexts: {success: true, 
            title: this.state.pageTxtData.success,
            text: this.state.pageTxtData.successText}});
      }
      
    }
  }

  handleHeaders = () => {
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    return headers;
  }
  checkSubscriptionDetails = async (token: string) => {
    const headers = this.handleHeaders();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkSubscriptionAPICallId = requestMessage.messageId;
    let lang = await getStorageData("language") || 'en';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkSubscriptionApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getMethod}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  fetchSubscriptionDetails = (token: string) => {
    const headers = {
      "content-type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSubAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionApiEndPoint+'?locale='+this.state.language
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${configJSON.getMethod}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  applyTranslation = async (lang: string) => {

    if(!lang)
      lang = 'en';
    this.setState({language: lang});

    this.setState({ pageTxtData: conditionSort(lang == 'ar', configJSON.subscriptionArText, configJSON.subscriptionEngText) });
    const script = document.createElement('script');
      script.innerHTML = `
        var wpwlOptions = {
        locale: "${lang}",
        brandDetection: true,
        brandDetectionType: "binlist",
        billingAddress: {},
        mandatoryBillingFields:{
            country: true,
            state: true,
            city: true,
            postcode: true,
            street1: true,
            street2: false
        }
      };
  `;
  document.body.appendChild(script);

  }
  // Customizable Area End
}
