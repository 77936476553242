import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider,
  FormControl,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { imgRightArrow, imgChangeEmail, imgChangePhone, imgChangePassword, imgDeactivateAccount, imgCustomerHelp } from "./assets";

import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { conditionSort } from "../../../components/src/Helper";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sidebarTabsStyle = () => {
    return {
      display: "flex",
      padding: "20px 40px",
      width:"full",
      "&.mainDiv": {
        width:"full" ,
        '@media (max-width: 565px)': {
          flexWrap: "wrap" ,
          gap:'3rem' ,
          padding: "20px"
        },
      },
      "& .fixedDiv": {
        display: "flex",
        flexDirection: "column",
        gap: "36px",
        borderRight: "1px solid #8C8C8C",
        width:"full",
      },
      "& .fixedDivAr": { 
        display: "flex",
        flexDirection: "column",
        gap: "36px",
        borderLeft: "1px solid #8C8C8C",
        width:"full"
      },
      "& .contentDiv": {
        width:"full",
        padding:"0px 80px",
        '@media (min-width: 550px)': {
          padding:"0 20px"
        },
        '@media (max-width: 550px)': {
          padding:"0px"
        },
      },
      "& .textContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 'full',
        "& .textSidebar": {
          color: "rgba(38, 38, 38, 0.80)",
          fontFamily: "Montserrat",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          cursor: "pointer",
        },
        "& .textActiveSidebar": {
          color: "#5412FC",
          fontFamily: "Montserrat",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          cursor: "pointer",
        },
        "& svg": {
           padding: "0px",
           width: "30px"
        },
        "& .arabicActiveSvg": {
          rotate: "180deg"
        }
      },
      "& .divider": {
        width: "242px",
        height: "1px",
        background: "#8C8C8C",
      },
    };
  };

  getNotificationsDetails = () => {
    const notificationsStyle = () => {
      return {
        display: 'content',
        width:"full",
        "& .text-notification": {
          color: "#5412FC",
          fontFamily: "Montserrat",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          margin: "0px 0px 10px 0px",
        },
        "& .arabHeading": {
          margin: "0px 10px 20px 0px"
        },
        "& .notifications-list": {
          borderRadius: "20px",
          border: "1px solid #8C8C8C",
          width: "full",
          padding: "24px 28px 17px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px 0px 20px 0px",
          
          "& .notification-text": {
            "& .notifications-title": {
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "24px",
            },
            "& .notifications-description": {
              color: "#8C8C8C",
              fontFamily: "Montserrat",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              padding: "10px 0 0 0",
              lineHeight: "24px",
            },
            "& .arabicDescription": {
              "& p": {
                margin: "0px"
              }
            }
          },
          "& svg": {
            width: "24px",
            height: "24px",
          },
        },
        "& .arabicNotificationList": {
          margin: "0px 10px 20px 0px",
          "& .arabicForwardIcon": {
            rotate: "180deg"
          }
        }
      };
    };
    return (
      <>
        <Box data-test-id="notifications-content" sx={notificationsStyle()}>
          <Typography className={this.handleLanguage() ? "text-notification" : "text-notification arabHeading"}>
            {this.handleLanguage() ? configJSON.NotificationTitle : configJSON.arabicNotification}
          </Typography>

          {this.state.allNotificationData && this.state.allNotificationData.map((item: any, idx: number) => {
            return (
              <Box
                key={idx}
                className={this.handleLanguage() ? "notifications-list" : "notifications-list arabicNotificationList"}
                data-test-id={`notification-list-${idx}`}
                onClick={() => this.handleReadNotification(item.id, item.attributes.app_url)}>
                <div className="notification-text">
                  <Typography data-test-id={`notification-headtitel-${idx}`} className="notifications-title" style={{color: item.attributes.is_read ? "#262626" : "#505AFF"}}>
                    {this.handleLanguage() ? item.attributes.headings : item.attributes.arabic_headings}
                  </Typography>
                  {this.handleLanguage() ?  
                  <Typography className="notifications-description">
                    {item.attributes.contents.replace('<p>','').replace('</p>', '')}
                  </Typography>
                    :
                    <Typography
                      className="notifications-description arabicDescription"
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.arabic_contents
                      }}
                    >
                    </Typography>
                  }
                </div>
                <div className={this.handleLanguage() ? "forwardIcon" : "arabicForwardIcon"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6.18337 3.13824L6.19181 3.1298L6.19191 3.12991C6.42438 2.9083 6.73323 2.78467 7.05441 2.78467C7.37559 2.78467 7.68444 2.90829 7.91691 3.12991L16.2763 11.0948C16.2763 11.0948 16.2763 11.0948 16.2763 11.0948C16.3988 11.2116 16.4964 11.352 16.563 11.5075C16.6297 11.6631 16.6641 11.8306 16.6641 11.9998C16.6641 12.1691 16.6297 12.3365 16.563 12.4921C16.4964 12.6477 16.3988 12.7881 16.2763 12.9048L6.18337 3.13824ZM6.18337 3.13824L6.17829 3.14333C6.06373 3.2535 5.97238 3.38552 5.90966 3.53161C5.84598 3.67993 5.81315 3.83965 5.81315 4.00106C5.81315 4.16247 5.84598 4.32219 5.90966 4.47051C5.9733 4.61875 6.06642 4.7525 6.18336 4.86364C6.18342 4.8637 6.18348 4.86375 6.18354 4.86381L13.675 12.0012L6.18354 19.1358C6.18344 19.1359 6.18335 19.136 6.18325 19.1361C6.06637 19.2472 5.97328 19.3809 5.90966 19.5291C5.84598 19.6774 5.81315 19.8372 5.81315 19.9986C5.81315 20.16 5.84599 20.3197 5.90966 20.468C5.97237 20.6141 6.0637 20.7461 6.17825 20.8563L6.18336 20.8614L6.18326 20.8615L6.19191 20.8697C6.42438 21.0913 6.73323 21.215 7.05441 21.215C7.37555 21.215 7.68436 21.0914 7.91682 20.8698C7.91685 20.8698 7.91688 20.8697 7.91691 20.8697L16.2763 12.9048L6.18337 3.13824Z"
                      fill="#262626"
                      stroke="#262626"
                    />
                  </svg>
                </div>
              </Box>
            );
          })}
        </Box>
      </>
    );
  };

  getSettingsDetails = () => {
    const settingsStyle = () => {
      return {
        display: "flex",
        flexDirection: "column",
        width:"60vw",
        "@media screen and (max-width:599px)": {
          width: "100%"
        },
        gap: "24px",
        "& .text-settings": {
          color: "#5412FC",
          fontFamily: "Montserrat",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
        },
        "& .settings-block": {
          width: "full",
          borderRadius: "20px",
          border: "2px solid transparent",
          background:
            "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
          boxSizing: "border-box",
          padding: "0 2rem",
          '@media (max-width: 755px)': {
            padding: "0 5px",
            width:"100%"
          },
          '@media (max-width: 564px)': {
            width:"85vw"
          },
          "& .settings-list": {
            margin: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
           width:"full",
           "& .title-box": {
            "@media screen and (max-width:599px)": {
              width: "90%"
            }
           },
            "& .title-content-settings": {
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",

              "& .text-content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                "& p": {
                  cursor: "pointer",
                },
                "& .settings-icon": {
                  width: "32px",
                  height: "32px",
                },
                "& .settings-title": {
                  color: "#262626",
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "36px",
                  padding: "0 20px",
                },
              },
            },
            "& .settings-description": {
              color: "#262626",
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              padding: "0 55px",
              '@media (max-width: 755px)': {
                width: "70%",
              },
            },
            "& .right-svg": {
              width: "30px",
              height: "30px",
            },
          },

          "& .settings-divider": {
            width: "full",
            height: "1px",
            background: "#262626",
          },
        },
        "& .deactivate-account-block": {
          padding: "18px 2rem",
          width: "full",
          borderRadius: "20px",
          border: "2px solid transparent",
          background:
            "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
          boxSizing: "border-box",
           '@media (max-width: 755px)': {
            padding: "0 5px",
          },
          
          "& .deactivate-account-title-content": {
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
            cursor: "pointer",
            "& .deactivate-account-text-content": {
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
              alignItems:"center",
              "& .deactivate-account-title": {
                color: "#262626",
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "36px",
                padding: "0 20px",
                cursor: "pointer",
              },
            },
            "& .deactivate-account-right-svg": {
              width: "40px",
              height: "40px",
            },
            "& img": {
              width: "30px",
              height: "30px",
            },
          },
        },
      };
    };
    const { userData } = this.state
    return (
      <>
        <Box data-test-id="settings-content" sx={settingsStyle()}>
          <Typography className="text-settings" data-test-id="settings-title-content">  
          {this.state.language=== "ar"? "إعدادات":"Settings"}</Typography>
          <Box className="settings-block">
            <Box data-test-id="change-email-content" onClick={() => this.handleNextPage('change_email')} className="settings-list">
              <Box className="title-box">
                <Box className="title-content-settings">
                  <div className="text-content">
                    <img src={imgChangeEmail} className="settings-icon" />
                    <Typography className="settings-title" data-test-id="settings-change-email">
                    {this.state.language=== "ar"? "تغيير معرف البريد الإلكتروني":"Change Email ID"}
                    </Typography>
                  </div>
                </Box>
                <Typography className="settings-description">
                  {userData?.attributes?.email}
                </Typography>
              </Box>
              <img src={imgRightArrow} className="right-svg" style={{rotate: conditionSort(this.state.language =='ar', '180deg', '0deg')}} />
            </Box>
            <Divider className="settings-divider" orientation="horizontal" />

            <Box data-test-id="change-phone-content" className="settings-list" onClick={() => this.handleNextPage('change_phone')}>
              <Box>
                <Box className="title-content-settings">
                  <div className="text-content">
                    <img src={imgChangePhone} className="" />
                    <Typography className="settings-title"  data-test-id="settings-change-mobile">
                     {this.state.language=== "ar"? "تغيير رقم الجوال":"Change Mobile Number"}
                    </Typography>
                  </div>
                </Box>
                <Typography className="settings-description">
                  {userData?.attributes?.full_phone_number}
                </Typography>
              </Box>
              <img src={imgRightArrow} className="right-svg" style={{rotate: conditionSort(this.state.language =='ar', '180deg', '0deg')}} />
            </Box>
            <Divider className="settings-divider" orientation="horizontal" />

            <Box data-test-id="change-password-content" className="settings-list" onClick={() => this.handleNextPage('change_password')}>
              <Box className="title-content-settings">
                <div className="text-content">
                  <img src={imgChangePassword} className="settings-icon"  />
                  <Typography className="settings-title"  data-test-id="settings-change-password">
                   {this.state.language=== "ar"? "تغيير كلمة المرور":"Change Password"}
                  </Typography>
                </div>
              </Box>
              <img src={imgRightArrow} className="right-svg" style={{rotate: conditionSort(this.state.language =='ar', '180deg', '0deg')}} />
            </Box>
            <Divider className="settings-divider" orientation="horizontal" />

            <Box data-test-id="need-help-content" className="settings-list" onClick={() => this.handleNextPage('help')}>
              <Box>
                <Box className="title-content-settings">
                  <div className="text-content">
                    <img src={imgCustomerHelp} className="settings-icon" />
                    <Typography className="settings-title"  data-test-id="settings-contact-nami">
                      {this.state.language=== "ar"? "اتصل بدعم نامي":"Contact Nami Support"}
                    </Typography>
                  </div>
                </Box>
                <Typography className="settings-description"  data-test-id="settings-change-support">
                   {this.state.language=== "ar"? "إذا كنت تواجه أي مشاكل، فلا تتردد في الاتصال بنا":"If you are facing any issues, feel free to contact us"}
                </Typography>
              </Box>
              <img src={imgRightArrow} className="right-svg" style={{rotate: conditionSort(this.state.language =='ar', '180deg', '0deg')}} />
            </Box>
          </Box>

          <Box className="deactivate-account-block">
            <Box data-test-id="deactivate-account-content" className="deactivate-account-title-content" onClick={() => this.handleNextPage('deactivate_account')}>
              <div className="deactivate-account-text-content">
                <img src={imgDeactivateAccount} className="settings-icon" />
                <Typography className="deactivate-account-title"  data-test-id="settings-change-deactive">
                  {this.state.language=== "ar"? "تعطيل الحساب":configJSON.DeactivateAccountText}
                </Typography>
              </div>
              <img src={imgRightArrow} className="right-svg" style={{rotate: conditionSort(this.state.language =='ar', '180deg', '0deg')}} />
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  getResourcesDetails = () => {
    const resourcesStyle = () => {
      return {
        display: 'content',
        "& .text-resources": {
          color: "#5412FC",
          fontFamily: "Montserrat",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          '@media (max-width: 790px)': {
            margin:"0 !important",
          },
        },
        "& .resources-list": {
          borderRadius: "20px",
          border: "1px solid #8C8C8C",
          width: "full",
          
          '@media (max-width: 500px)': {
            width: "auto",
            marginRight: "0px !important",
            marginLeft: "0px !important"
          },

         
          
          "& .title-content-resources": {
            display: "flex",
            alignItems: "center",
            "& .logo-resources": {
              width: "100px",
              height: "100px",
              flexShrink: 0,
              borderRadius: "20px",
              background: "#D9D9D9",
              margin: "42px",

              '@media (min-width: 500px)': {
                margin:"1rem "
              },
              '@media (max-width: 350px)': {
                margin:"0.9rem "
              },
            },

            "& .resources-text": {
              width: "100%",
              "& .text-content": {
                display: "flex",
                justifyContent: "space-between",
                '@media (max-width: 790px)': {
                  flexWrap:"wrap"
                },
                "& .resources-title": {
                  color: "#262626",
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                },
                "& .readMore": {
                  width: "160px",
                  height: "36px",
                  flexShrink: 0,
                  borderRadius: "12px",
                  border: "2px solid #10BFB8",
                  '@media (min-width: 500px)': {
                    width:"fit-content "
                  },
                  '@media (max-width: 790px)': {
                    width:"auto"
                  },
                  "& span": {
                    color: "#10BFB8",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                  },
                },
              },
              "& .resources-description": {
                color: "#8C8C8C",
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
              },
            },

          },
          "& .resources-long-description": {
            width: "full",
            color: "#000",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            padding: "0px 42px 56px",
           
          },
        },
      };
    };
    return (
      <>
        <Box data-test-id="resources-content" sx={resourcesStyle()}>
          <Typography className="text-resources" style={{margin: conditionSort(this.state.language == 'en', '0px 0px 20px 120px', '0px 120px 20px 0px')}}>
            {this.state.detailsText.resources}</Typography>
          <div style={{overflowY: 'scroll', height: '500px'}}>
          {this.state.resoucesList.map((resource: any, keyId: number) => {
            let words = resource.attributes.content.split(' ');
            if (words.length > 50) {
              const truncatedText = words.slice(0, 50).join(' ') + '...';
              resource.attributes.content = truncatedText;
            }
            return (
              <Box key={keyId} className="resources-list" style={{margin: conditionSort(this.state.language == 'en', '20px 30px 43px 120px', '20px 120px 43px 30px')}}>
                <Box className="title-content-resources">
                  <div>
                    <img className="logo-resources" src={resource.attributes.image?.url} alt={resource.attributes.title}/>
                  </div>
                  <div className="resources-text" style={{padding: conditionSort(this.state.language == 'en', '0px 42px 0px 0px', '0px 0px 0px 42px')}}>
                    <div className="text-content">
                      <Typography className="resources-title">
                      {conditionSort(this.state.language == 'ar', resource.attributes.arabic_title, resource.attributes.title)}
                      </Typography>
                      <Button
                        onClick={() => this.navigateTo(resource.id)}
                        data-testid="readMore"
                        className="readMore"
                      >
                        {this.state.detailsText.readMore}
                      </Button>
                    </div>

                    <Typography className="resources-description">
                      {conditionSort(this.state.language == 'ar', resource.attributes.arabic_sub_title, resource.attributes.sub_title)}
                    </Typography>
                  </div>

                </Box>
                <Box>
                  <Typography className="resources-long-description">
                  {conditionSort(this.state.language == 'ar', resource.attributes.arabic_details, resource.attributes.content)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          </div>
        </Box>
      </>
    )
  };

  getNotificationSettingsDetails = () => {
    const resourcesStyle = () => {
      return {
        display: 'content',
        "& .text-resources": {
          color: "#5412FC",
          fontFamily: "Montserrat",
          fontSize: "30px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
          margin: "0px 0px 67px 120px",
        },
        "& .notification-setting-list": {
          width: "100%",
          margin: "20px 0px 60px 120px",

          '@media (max-width: 650px)': {
            marginLeft:"0"
          },

          '@media (max-width: 768px)': {
            marginLeft:"0"
          },

          '@media (max-width: 1200px)': {
            marginLeft:"0"
          },

       
              "& .notification-main-title-content": {
                padding: "0px 42px 0px 0px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                
                "& .notification-setting-title": {
                  color: "#5412FC",
                  fontFamily: "Montserrat",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                },
                "& .back-button": {
                  width: "120px",
                  height: "36px",
                  flexShrink: 0,
                  marginRight: "300px",
                  backgroundColor:"none",
                 
                
                  "& span": {
                    color: "#000000",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                  },
                },
              },
        },
        "& .notification-list-main": {
          width: "100%",
          margin: "20px 0px 30px 120px",
          '@media (max-width: 650px)': {
            margin:"0"
          },

          '@media (max-width: 768px)': {
            margin:"0"
          },
         
          "& .notification-main-title-content": {
            display: "flex",
            alignItems: "center",
            margin: "42px 0px  20px 42px",
            padding: "0px 250px 0px 0px",
        
        
            "& .notification-main-text": {
              padding: "0px 42px 0px 0px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              '@media (min-width: 650px)': {
                justifyContent: "space-around",
              },    
                "& .notification-main-title": {
                  color: "#262626",
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "24px",
                  '@media (min-width: 650px)': {
                    fontSize: "16px",
                  }
                },
                "& .auto-sign-section": {
                   "& .MuiFormControlLabel-root": {
                      
                      "& .MuiFormControlLabel-label.Mui-disabled": {
                        opacity: 0.6,
                      },
                      "& .MuiSwitch-root": {
                        width: "84px",
                        height: "40px",
                        padding: "0",
                        borderRadius: "20px",
                        "@media (max-width-440px)": {
                          width: "42px",
                          height: "20px"
                        },
                        "& .MuiButtonBase-root": {
                          padding: "4px 5px",
                          "& .MuiSwitch-thumb": {
                            width: "32px",
                            height: "32px",
                          },
                        },
                        "& .MuiSwitch-colorSecondary.Mui-checked": {
                          color: "#fff",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          transform: "translateX(42px)",
                        },
                        "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: "#5412FC",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                          opacity: 1,
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "#8C8C8C",
                          opacity: 1,
                        },
                        "& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
                          opacity: 0.4,
                        },
                        "& .MuiSwitch-colorSecondary.Mui-disabled": {
                          opacity: 0.8,
                          color: "white",
                        },
                      },
                    },
                  
                },
            },

          },
          "& .notification-check":{
            marginLeft: "50px",
            width:"65%" , 
            
            '@media (max-width: 550px)': {
              width:"80%",
              marginLeft: "0px"
            },
        
          },
          
        },
      };
    };
    const svgData :any = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.639 4.30245L23.6305 4.29401L23.6304 4.29411C23.3515 4.02817 22.9808 3.87982 22.5954 3.87982C22.2101 3.87982 21.8395 4.02814 21.5605 4.29402L23.639 4.30245ZM23.639 4.30245L23.6469 4.31042C23.7842 4.4421 23.8936 4.60003 23.9686 4.77483C24.0446 4.95182 24.0838 5.14241 24.0838 5.33501C24.0838 5.52762 24.0446 5.71821 23.9686 5.8952C23.8926 6.07215 23.7815 6.23181 23.6418 6.36446C23.6418 6.36448 23.6418 6.3645 23.6418 6.36451L13.5264 16.0019L23.6418 25.6355C23.7814 25.7682 23.8926 25.9279 23.9686 26.1048C24.0446 26.2818 24.0838 26.4724 24.0838 26.665C24.0838 26.8576 24.0446 27.0482 23.9686 27.2252C23.8936 27.4 23.7842 27.5579 23.647 27.6896L23.639 27.6976L23.6391 27.6977L23.6304 27.7059C23.3515 27.9719 22.9808 28.1202 22.5954 28.1202C22.21 28.1202 21.8394 27.9719 21.5604 27.7059L10.4145 17.086C10.4145 17.086 10.4145 17.086 10.4145 17.086C10.2675 16.9459 10.1504 16.7774 10.0704 16.5908L10.5287 16.3944L10.0704 16.5908C9.99046 16.4041 9.94922 16.2031 9.94922 16C9.94922 15.7969 9.99046 15.5959 10.0704 15.4093L10.53 15.6062L10.0704 15.4093C10.1504 15.2226 10.2675 15.0541 10.4145 14.914C10.4145 14.914 10.4145 14.914 10.4145 14.914L21.5604 4.29411L23.639 4.30245Z" fill="#262626" stroke="#262626"/>
    </svg>` 
    const encodedSvgData:any = `data:image/svg+xml;utf8,${encodeURIComponent(svgData)}`;
    return (
      <>
        <Box data-test-id="note-setting-content-list" sx={resourcesStyle()}>
        <Box  className="notification-setting-list">
            <Box className="notification-main-title-content" >
                  <Typography className="notification-setting-title" data-test-id="note-setting-content-list-title">
                      {this.state.language=== "ar"? configJSON.NotificationSettingTitleArabic:configJSON.NotificationSettingTitle}
                  </Typography>
                  <Button data-test-id="back-btn"
                        onClick={() => this.handleBackbtn()}
                        className="back-button"
                      >
         <img src={encodedSvgData} alt="Back" width="40" height="40" style={{paddingTop: "7px", transform: conditionSort(this.state.language == 'ar', "rotate(180deg)", "rotate(0deg)")}} />
   { this.state.language=== "ar"? "عودة" :"Back"}
                  </Button>
            </Box>
        </Box>
         
          {configJSON.notificationsSettingList.map((item: any, idx: number) => {
            return (
              <Box key={idx} data-test-id="note-setting-content-main" className="notification-list-main">
                <Box className="notification-main-title-content">
                  <div className="notification-main-text">
                      <Typography className="notification-main-title" data-test-id={`option-${idx}`}>
                        { this.state.language=== "ar"? item.titleMainArabic : item.titleMain}
                      </Typography>
                      <div className="auto-sign-section">
                      <FormControl>
                          <FormControlLabel 
                           data-test-id={`checkbox-${idx}`}   
                          control={
                          <Switch
                          data-test-id={`checkbox-other-${idx}`}           
                          checked={item.valueMain==="sms_active" ?this.state.sms_active:this.state.email_active}
                          onChange={(e)=>this.handleNotificationSettingMain(e)}
                          name={item.valueMain}
                        />
                        }
                        label=""
                        />
                </FormControl>
                </div>
                  </div>
                </Box>
                <Box className="notification-check">
                
                  <FormGroup>
                  {
                    item.options.map((option:any,idop:number)=>{
                     return  <FormControlLabel key={option.id}
                     data-test-id={`checkbox-option-${option.id}`}

                      control={<Checkbox 
                        data-test-id={`checkbox-option-other-${option.id}`}
                        className="checkbox"
                        checked={this.getStateValue(option.value)}
                        onChange={(e)=>this.handleNotificationSettingMain(e)}
                        name={option.value} />}
                      label={ this.state.language=== "ar"? option.titleArabic :option.title}
                      disabled={this.shouldDisableCheckbox(option)} 
                    />
                    })
                  }
              </FormGroup>
            </Box>
          </Box>
            );
          })}
        </Box>
      </>
    )
  };

  getDataNew=()=>{
    return (<>
      <div className="textContent">
              <Typography
              style={{ lineHeight: '35px' }}
                data-test-id="notification-setting-content"
                onClick={() => this.onChangeTab("notificationSettings")}
                className={`${this.state.activeTab === "notificationSettings" ? "textActiveSidebar" : 'textSidebar'}`}
              >
                 {this.state.language=== "ar"? configJSON.NotificationSettingTitleArabic:configJSON.NotificationSettingTitle}
              </Typography>
              {this.state.activeTab === "notificationSettings" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={this.handleLanguage() ? "activeSvg" : "arabicActiveSvg" }
                >
                  <path
                    d="M10.5472 5.45784L10.5387 5.46628L10.5278 5.47715C10.3679 5.63034 10.2405 5.81416 10.1531 6.01768L10.6126 6.21493L10.1531 6.01769C10.0648 6.22333 10.0193 6.4448 10.0193 6.6686C10.0193 6.8924 10.0648 7.11386 10.1531 7.31951L10.6126 7.12227L10.1531 7.31951C10.2414 7.52501 10.3704 7.71042 10.5325 7.86449C10.5326 7.86461 10.5327 7.86473 10.5329 7.86485L23.2723 20.0023L10.5329 32.1348C10.3706 32.289 10.2414 32.4745 10.1531 32.6802L10.5931 32.8691L10.1531 32.6802C10.0648 32.8858 10.0193 33.1073 10.0193 33.3311C10.0193 33.5549 10.0648 33.7764 10.1531 33.982L10.5931 33.7931L10.1531 33.982C10.2405 34.1855 10.3679 34.3693 10.5278 34.5225L10.5387 34.5334L10.5386 34.5335L10.5472 34.5418C10.8727 34.852 11.3051 35.0251 11.7547 35.0251C12.2044 35.0251 12.6368 34.852 12.9623 34.5418L26.8946 21.2668C26.8947 21.2668 26.8947 21.2668 26.8947 21.2668C27.0662 21.1034 27.2028 20.9068 27.2961 20.689C27.3894 20.4713 27.4375 20.2368 27.4375 19.9998C27.4375 19.7629 27.3894 19.5284 27.2961 19.3106C27.2028 19.0929 27.0662 18.8963 26.8947 18.7329C26.8947 18.7329 26.8947 18.7329 26.8946 18.7328L12.9622 5.45794C12.6368 5.14769 12.2044 4.97461 11.7547 4.97461C11.3051 4.97461 10.8727 5.14769 10.5473 5.45794L10.5472 5.45784Z"
                    fill="#5412FC"
                    stroke="#5412FC"
                  />
                </svg>
              )}
            </div>
    </>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <Box sx={this.sidebarTabsStyle()} className="mainDiv">
          <Box className={conditionSort(this.state.language == 'en', "fixedDiv", 'fixedDivAr')}>
            <div className="textContent">
              <Typography
                data-test-id="notificationstab"
                onClick={() => this.onChangeTab("notifications")}
                className={`${this.state.activeTab === "notifications" ? "textActiveSidebar" : 'textSidebar'}`}
              >
                {this.handleLanguage() ? configJSON.NotificationTitle : configJSON.arabicNotification}
              </Typography>
              {this.state.activeTab === "notifications" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  className={this.handleLanguage() ? "activeSvg" : "arabicActiveSvg" }
                >
                  <path
                    d="M10.5472 5.45784L10.5387 5.46628L10.5278 5.47715C10.3679 5.63034 10.2405 5.81416 10.1531 6.01768L10.6126 6.21493L10.1531 6.01769C10.0648 6.22333 10.0193 6.4448 10.0193 6.6686C10.0193 6.8924 10.0648 7.11386 10.1531 7.31951L10.6126 7.12227L10.1531 7.31951C10.2414 7.52501 10.3704 7.71042 10.5325 7.86449C10.5326 7.86461 10.5327 7.86473 10.5329 7.86485L23.2723 20.0023L10.5329 32.1348C10.3706 32.289 10.2414 32.4745 10.1531 32.6802L10.5931 32.8691L10.1531 32.6802C10.0648 32.8858 10.0193 33.1073 10.0193 33.3311C10.0193 33.5549 10.0648 33.7764 10.1531 33.982L10.5931 33.7931L10.1531 33.982C10.2405 34.1855 10.3679 34.3693 10.5278 34.5225L10.5387 34.5334L10.5386 34.5335L10.5472 34.5418C10.8727 34.852 11.3051 35.0251 11.7547 35.0251C12.2044 35.0251 12.6368 34.852 12.9623 34.5418L26.8946 21.2668C26.8947 21.2668 26.8947 21.2668 26.8947 21.2668C27.0662 21.1034 27.2028 20.9068 27.2961 20.689C27.3894 20.4713 27.4375 20.2368 27.4375 19.9998C27.4375 19.7629 27.3894 19.5284 27.2961 19.3106C27.2028 19.0929 27.0662 18.8963 26.8947 18.7329C26.8947 18.7329 26.8947 18.7329 26.8946 18.7328L12.9622 5.45794C12.6368 5.14769 12.2044 4.97461 11.7547 4.97461C11.3051 4.97461 10.8727 5.14769 10.5473 5.45794L10.5472 5.45784Z"
                    fill="#5412FC"
                    stroke="#5412FC"
                  />
                </svg>
              )}
            </div>
            <Divider className="divider" orientation="horizontal" />

            <div className="textContent">
              <Typography
                data-test-id="settings"
                onClick={() => this.onChangeTab("settings")}
                className={`${this.state.activeTab === "settings" ? "textActiveSidebar" : 'textSidebar'}`}
              >
               {this.state.language=== "ar"? configJSON.SettingsTitleArabic:configJSON.SettingsTitle}
              </Typography>
              {this.state.activeTab === "settings" && (
                <svg
                  className={this.handleLanguage() ? "activeSvg" : "arabicActiveSvg" }
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M10.5472 5.45784L10.5387 5.46628L10.5278 5.47715C10.3679 5.63034 10.2405 5.81416 10.1531 6.01768L10.6126 6.21493L10.1531 6.01769C10.0648 6.22333 10.0193 6.4448 10.0193 6.6686C10.0193 6.8924 10.0648 7.11386 10.1531 7.31951L10.6126 7.12227L10.1531 7.31951C10.2414 7.52501 10.3704 7.71042 10.5325 7.86449C10.5326 7.86461 10.5327 7.86473 10.5329 7.86485L23.2723 20.0023L10.5329 32.1348C10.3706 32.289 10.2414 32.4745 10.1531 32.6802L10.5931 32.8691L10.1531 32.6802C10.0648 32.8858 10.0193 33.1073 10.0193 33.3311C10.0193 33.5549 10.0648 33.7764 10.1531 33.982L10.5931 33.7931L10.1531 33.982C10.2405 34.1855 10.3679 34.3693 10.5278 34.5225L10.5387 34.5334L10.5386 34.5335L10.5472 34.5418C10.8727 34.852 11.3051 35.0251 11.7547 35.0251C12.2044 35.0251 12.6368 34.852 12.9623 34.5418L26.8946 21.2668C26.8947 21.2668 26.8947 21.2668 26.8947 21.2668C27.0662 21.1034 27.2028 20.9068 27.2961 20.689C27.3894 20.4713 27.4375 20.2368 27.4375 19.9998C27.4375 19.7629 27.3894 19.5284 27.2961 19.3106C27.2028 19.0929 27.0662 18.8963 26.8947 18.7329C26.8947 18.7329 26.8947 18.7329 26.8946 18.7328L12.9622 5.45794C12.6368 5.14769 12.2044 4.97461 11.7547 4.97461C11.3051 4.97461 10.8727 5.14769 10.5473 5.45794L10.5472 5.45784Z"
                    fill="#5412FC"
                    stroke="#5412FC"
                  />
                </svg>
              )}
            </div>
            <Divider className="divider" orientation="horizontal" />

            <div className="textContent">
              <Typography
                data-test-id="resources"
                onClick={() => this.onChangeTab("resources")}
                className={`${this.state.activeTab === "resources" ? "textActiveSidebar" : 'textSidebar'}`}
              >
                {this.state.detailsText.resources}
              </Typography>
              {this.state.activeTab === "resources" && (
                <svg
                  style={{rotate: conditionSort(this.state.language == 'ar', '180deg', '0deg')}}
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M10.5472 5.45784L10.5387 5.46628L10.5278 5.47715C10.3679 5.63034 10.2405 5.81416 10.1531 6.01768L10.6126 6.21493L10.1531 6.01769C10.0648 6.22333 10.0193 6.4448 10.0193 6.6686C10.0193 6.8924 10.0648 7.11386 10.1531 7.31951L10.6126 7.12227L10.1531 7.31951C10.2414 7.52501 10.3704 7.71042 10.5325 7.86449C10.5326 7.86461 10.5327 7.86473 10.5329 7.86485L23.2723 20.0023L10.5329 32.1348C10.3706 32.289 10.2414 32.4745 10.1531 32.6802L10.5931 32.8691L10.1531 32.6802C10.0648 32.8858 10.0193 33.1073 10.0193 33.3311C10.0193 33.5549 10.0648 33.7764 10.1531 33.982L10.5931 33.7931L10.1531 33.982C10.2405 34.1855 10.3679 34.3693 10.5278 34.5225L10.5387 34.5334L10.5386 34.5335L10.5472 34.5418C10.8727 34.852 11.3051 35.0251 11.7547 35.0251C12.2044 35.0251 12.6368 34.852 12.9623 34.5418L26.8946 21.2668C26.8947 21.2668 26.8947 21.2668 26.8947 21.2668C27.0662 21.1034 27.2028 20.9068 27.2961 20.689C27.3894 20.4713 27.4375 20.2368 27.4375 19.9998C27.4375 19.7629 27.3894 19.5284 27.2961 19.3106C27.2028 19.0929 27.0662 18.8963 26.8947 18.7329C26.8947 18.7329 26.8947 18.7329 26.8946 18.7328L12.9622 5.45794C12.6368 5.14769 12.2044 4.97461 11.7547 4.97461C11.3051 4.97461 10.8727 5.14769 10.5473 5.45794L10.5472 5.45784Z"
                    fill="#5412FC"
                    stroke="#5412FC"
                  />
                </svg>
              )}
            </div>

            <Divider className="divider" orientation="horizontal" />
            {this.getDataNew()}
          </Box>

          {/* Second Box to the right of the fixed Box */}
          <Box className="contentDiv">
            {this.state.activeTab === "notifications" && this.getNotificationsDetails()}
            {this.state.activeTab === "settings" && this.getSettingsDetails()}
            {this.state.activeTab === "resources" && this.getResourcesDetails()}
            {this.state.activeTab === "notificationSettings" && this.getNotificationSettingsDetails()}
          </Box>

        </Box>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
