import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { setStorageData,getStorageData,removeStorageData } from "../../../framework/src/Utilities";
import TranslateText from "../../../components/src/GoogleTranslateAPI";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: any;
  email: string | null;
  emailError: boolean,
  accountNotValid: boolean,
  passwordError: boolean,
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  focusEmail: boolean;
  focusPassword: boolean;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  modalOpen: boolean;
  otpState: any;
  emailCodeFocused: boolean;
  otpError: boolean;
  disabled:boolean;
  Active:boolean;
  copyPassword:any;
  resendOtp:boolean;
  remainingTime: any;
  timer: any,
  isTimeOver: boolean,
  typeOfUser: string,
  isUserSubscribed: string,
  language:string;
  signUpPageText : {
    headerTitleArabic: string;
    emailIdTextArabic: string;
    passwordTextArabic: string;
    forgotPasswordTextArabic: string;
    forgotEmailIdTextArabic: string;
    addNewUserTextArabic: string;
    signUpTextArabic: string;
    wrongEmailIdOrPasswordArabic: string;
    placeHolderEmail: string;
    placeHolderPassword: string;
    labelHeader: string;
    btnTxtLogin: string;
    labelRememberMe: string;
    btnTxtSocialLogin: string;
    labelOr: string;
    headerTitle: string;
    wrongEmailIdOrPassword:string;
    emailIdText: string;
    passwordText:string
    forgotPasswordText: string;
    forgotEmailIdText: string;
    signUpText: string;
    signInText: string;
    signInTextArabic: string;
    adduserText:string;
    verificationText:string;
    enterCodeSentText:string;
    invalidCodeText:string;
    timeRemainingText:string;
    sText:string;
    resendText:string;
    verifyCodeText:string;
    successText:string;
    verificationSuccessFulText:string;
    dashboardRedirectionText:string;
  }

  otpText: {
    verificationText: string;
    enterCodeSentText: string;
    invalidCodeText: string;
    timeRemainingText: string;
    sText: string;
    resendText: string;
    verifyCodeText: string;
    successText: string;
    verificationSuccessFulText: string;
    dashboardRedirectionText: string;
  }

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  apiOtpVerify: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      focusEmail: false,
      focusPassword: false,
      accountNotValid: false,
      emailError: false,
      passwordError: false,
      enablePasswordField: false,
      copyPassword:'',
      checkedRememberMe: false,
      language:'en',
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      otpText: {
        verificationText: "",
        enterCodeSentText: "",
        invalidCodeText: "",
        timeRemainingText: "",
        sText: "",
        resendText: "",
        verifyCodeText: "",
        successText: "",
        verificationSuccessFulText: "",
        dashboardRedirectionText: "",
      },
      signUpPageText : {
        signUpTextArabic: configJSON.signUpTextArabic,
        forgotEmailIdTextArabic: configJSON.forgotEmailIdTextArabic,
        addNewUserTextArabic: configJSON.addNewUserTextArabic,
        placeHolderEmail: configJSON.placeHolderEmail,
        placeHolderPassword: configJSON.placeHolderPassword,
        labelHeader: configJSON.labelHeader,
        btnTxtLogin: configJSON.btnTxtLogin,
        labelRememberMe: configJSON.labelRememberMe,
        btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
        labelOr: configJSON.labelOr,
        headerTitle: configJSON.labelTitle,
        headerTitleArabic: configJSON.labelTitleArabic,
        wrongEmailIdOrPassword: configJSON.wrongEmailIdOrPassword,
        wrongEmailIdOrPasswordArabic: configJSON.wrongEmailIdOrPasswordArabic,
        emailIdText: configJSON.emailId,
        emailIdTextArabic: configJSON.emailIdTextArabic,
        passwordText: "Password*",
        passwordTextArabic: configJSON.passTextArabic,
        forgotPasswordText: configJSON.forgotPasswordText,
        forgotPasswordTextArabic: configJSON.forgetPasswordTextArabic,
        forgotEmailIdText: configJSON.forgotEmailIdText,
        signUpText: configJSON.signUpText,
        signInText: configJSON.signInText,
        signInTextArabic: configJSON.signInTextArabic,
        adduserText: configJSON.addNewUserText,
        verificationText: configJSON.verificationText,
        enterCodeSentText: configJSON.enterCodeSentText,
        invalidCodeText: configJSON.invalidCodeText,
        timeRemainingText: configJSON.timeRemainingText,
        sText:configJSON.sText,
        resendText:configJSON.resendText,
        verifyCodeText:configJSON.verifyCodeText,
        successText:configJSON.successText,
        verificationSuccessFulText:configJSON.verificationSuccessFulText,
        dashboardRedirectionText:configJSON.dashboardRedirectionText
      },
      modalOpen: false,
      otpState: "",
      emailCodeFocused: false,
      otpError: false,
      disabled:true,
      Active:false,
      resendOtp:false,
      remainingTime: '',
      timer: null,
      isTimeOver: false,
      typeOfUser : "",
      isUserSubscribed: "",
    };
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.verifyOTP = this.verifyOTP.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if (this.props.navigation?.history?.location?.state?.email && this.props.navigation?.history?.location?.state?.password) {
      this.setState({ email: this.props.navigation?.history?.location?.state.email,
         password: this.props.navigation?.history?.location?.state.password })
    }
    const getTimer = await getStorageData("signINtimer");

    this.setState({ remainingTime: getTimer || 300});

    this.startTimer()
    this.getLang();
    document.addEventListener('keyup', this.handleKeyUp);
    // Customizable Area End
  }

  // Customizable Area Start

  verifyOTP(e:any) {
  
    const { otpState } = this.state;
    if (otpState.length === 4) {
      this.doLogIn = this.doLogIn.bind(this);
    this.doLogIn(e);
    }
  }

  handleKeyUp(event:any) {
    if (event.key === 'Enter') {
      this.verifyOTP(event);
    }
  }
  handleClickOutside = (event:any) => {
      this.verifyOTP(event);
  };

  saveRemainingTimeToLocalStorage = async () => {
    await setStorageData("signINtimer", this.state.remainingTime.toString());
  };
  startTimer = () => {
    this.clearTimer();
    const timer = setInterval(this.tick, 1000);
    this.setState({ timer });
  };


  getLang = async () => {
    let lang = await getStorageData('language') || 'en';
     this.setState({ language: lang },  () => this.applyTranslation());
  }

  applyTranslation = async () => {
    let translatedObj : any = await TranslateText(this.state.signUpPageText, this.state.language, 'en')
      this.setState({ signUpPageText: translatedObj, otpText: conditionSort(this.state.language == 'ar', configJSON.otpAr, configJSON.otpEn) });
  }

  getIfCondtionForLang = (ifCondition: boolean, value1:string | number,value2:string | number) =>{
    if(ifCondition){
      return value1
    }else{
      return value2
    }
  }

  tick = () => {
    if (this.state.remainingTime > 0) {
      this.setState((prevState) => ({
        remainingTime: prevState.remainingTime - 1
      }),
        this.saveRemainingTimeToLocalStorage

      );
    } else {
      this.clearTimer();
      this.setState({ isTimeOver: true });
      this.saveRemainingTimeToLocalStorage();


    }
  };
  handleResendOTP = () => {
    this.setState({ isTimeOver: false, remainingTime: 300 });
    this.startTimer();
  };

  clearTimer = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  };

  

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen })

    setTimeout(() => {
      this.setState({ modalOpen: !this.state.modalOpen })
      if(!this.state.isUserSubscribed) {
        this.props.navigation.navigate("BuyerSubscription");
        return;
      }
      if(this.state.typeOfUser === "buyer"){
        this.props.navigation.navigate("Catalogue")
      }else{
        this.props.navigation.navigate("StartUpDetails")
      }
    }, 2000);

  }
  
    handleOTPState = (val: any) => {
      // Ensure that val is a string
      const trimmedValue = (typeof val === 'string' ? val : '').replace(/\s/g, '');
      const isFilled = trimmedValue.length === 4;
      this.setState({ otpState: val, Active: isFilled, disabled: !isFilled });
    }


  handleVerificationPageSubmit = () => {
    this.setState({ modalOpen: !this.state.modalOpen })
  }
  doSignUp = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegBlock"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  navigatetologin = async (responseJson: any) => {
    await setStorageData("signINtimer", 300);

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      await setStorageData("token", responseJson.meta.token);
      await setStorageData("given_feedback", responseJson.meta.given_feedback.toString());
      // store user type for dynamic header
      responseJson.meta.user_type = conditionSort(['المشتري', 'buyer'].includes(responseJson.meta.user_type), 'buyer', 'seller');
      await setStorageData("typeOfUser", responseJson.meta.user_type);
      await setStorageData("isUserSubscribed", responseJson.meta.subscribed);
      let lang = await getStorageData('language')
      if (!lang) {
        await setStorageData("language", "en");
      }
      this.setState({typeOfUser : responseJson.meta.user_type, isUserSubscribed: responseJson.meta.subscribed})
    }
    if (typeof this.state.otpState === 'string' && this.state.otpState.trim().length > 0) {
      this.toggleModal()
      this.clearTimer()
       await removeStorageData('signINtimer');

    } else {
      this.props.navigation.navigate("EmailOtpVerification", {
        email: this.state.email,
        password: this.state.copyPassword,
        
      });
    }

  }
 

  doLogIn(e: any, resendOTP = false): boolean {
    
this.setState({resendOtp:resendOTP})

if(resendOTP){
  this.setState({otpError:false, otpState:"", disabled:true, isTimeOver: false,  remainingTime: 300 });
  this.startTimer();
}

    if (
      this.state.email === null ||
      this.state.email?.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.setState({ emailError: true })
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({ passwordError: true })
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    let attrs
    let sendotp = ""
    let tempPassword = this.state.copyPassword===null?this.props.navigation?.history?.location?.state.password:this.state.copyPassword
    if (!resendOTP) {
      sendotp= this.state.otpState.trim()
    }
      attrs ={
        email: this.state.email ?? this.props.navigation?.history?.location?.state.email,
        password: this.state.copyPassword===''?this.state.password:tempPassword,
        otp: sendotp
    }
 

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signInApiEndPoint}?locale=${this.state.language}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  handleSignInColor = () => {
    const { password, email, accountNotValid } = this.state;
    if (password && email && this.emailReg.test(email)) {
      return true;
    }
    return false;
  }

  handleForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  }
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: any) => {
      this.setState({ copyPassword: text ,password:text});
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };


  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleForgotEmail = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotEmailId");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
  }

  getContent = (englishContent:string,arabicContent:string) => {
    return this.state.language === "en" ? englishContent : arabicContent;
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.validationApiCallId && responseJson) {
      const regexData = responseJson.data?.[0];

      if (regexData?.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      
      if (responseJson && responseJson.errors) {
    if (!this.state.resendOtp) {
      this.setState({ accountNotValid: true ,otpError:true, signUpPageText: {...this.state.signUpPageText, wrongEmailIdOrPassword: conditionSort(this.state.language == 'ar', responseJson.errors[0].failed_login_arabic, responseJson.errors[0].failed_login) }});
      this.handleSignInColor();
    }
      } else {
        if (!this.state.resendOtp) {
          this.navigatetologin(responseJson);
        }

      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
